// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("jquery")
import "bootstrap"
import intlTelInput from 'intl-tel-input';
import utilsScript from 'intl-tel-input/build/js/utils';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import Selectize from '@selectize/selectize'

Rails.start()
Turbolinks.start()
ActiveStorage.start()
global.$ = require("jquery")
global.intlTelInput = intlTelInput;
global.utilsScript = utilsScript;
global.Chart = Chart;
global.Selectize = Selectize;

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover()
})

require("./custom")
